.creation-content {
  --background: #efeff4;
}

.creation-card {
  box-shadow: 0px 0px 0px;
}

.color-button {
  --box-shadow: none;
}

.creation-error {
  margin-top: 10px;
}

/*
ion-popover::part(search-popover) {
  --box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}
*/

/*
.search-popover {
  --box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}
*/

.video-picker {
  --padding-bottom: 0px !important;
  --padding-end: 0px !important;
  --padding-start: 0px !important;
  --padding-top: 0px !important;
  --placeholder-color: #hhhhhh !important;
}
