.detail-item {
  margin: 0.9vh 0.9vh 0.9vh 0.9vh;
  background-color: #f5f5f5;
  box-shadow: none;
}

/* Source: https://stackoverflow.com/a/39415449 */
.display-linebreak {
  white-space: pre-line;
}

ion-chip.small {
  height: 20px;
  font-size: 12px;
}

ion-chip.large {
  height: 50px;
  padding: 25px;
  border-radius: 25px;
}

ion-chip.wide {
  justify-content: center;
  display: flex;
}