.connection-content {
    --background: #efeff4;
  }
  
.connection-card {
  box-shadow: 0px 0px 0px;
}

.color-button {
  --box-shadow: none;
}

.color-box {
  float: left;
  height: 20px;
  width: 20px;
  border: 0px;
  clear: both;
  margin-right: 10px;
  border-radius: 4px;
}

.color-box-picker {
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 4px;
  margin-left: 2px;
}

.color-box-picker-selected {
  outline: 1px dashed gray;
}

.creation-content {
  --background: #efeff4;
}

.creation-card {
  box-shadow: 0px 0px 0px;
}
