.providerflow {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.providerflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.providerflow aside .description {
  margin-bottom: 10px;
}

.providerflow aside .title {
  font-weight: 700;
  margin-bottom: 5px;
}

.providerflow aside .transform {
  margin-bottom: 20px;
}

.providerflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.providerflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .providerflow {
    flex-direction: row;
  }

  .providerflow aside {
    width: 20%;
    max-width: 250px;
  }
}

/* Hide React Flow connection handles */
.react-flow__handle {
  visibility: hidden;
}
