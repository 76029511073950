.parent {
  width: 100%;
  height: 100%;
}

.library-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.system-item {
  width: 100% !important;
  margin: 0.9vh 0.9vh 0.9vh 0.9vh;
  background-color: #f5f5f5;
  box-shadow: none;
  opacity: 0.95;
}

/* https://cssgradient.io/ */
.gradient-background {
  background: linear-gradient(
    90deg,
    rgba(235, 239, 255, 1) 0%,
    rgba(231, 238, 251, 1) 55%,
    rgba(247, 238, 255, 1) 100%
  );
}

.systems-list {
  height: 50vh;
}

.color-box {
  float: left;
  height: 20px;
  width: 20px;
  border: 0px;
  clear: both;
  margin-right: 10px;
  border-radius: 4px;
}

.small-icon {
  font-size: 120%;
  margin-right: 3px;
}