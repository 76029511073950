/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** main toolbar in header **/
  --ion-toolbar-background: transparent;
  --ion-background-color: #ffffff;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Node action colors **/
  --ion-color-btaction: #dde3fd;
  --ion-color-btaction-rgb: 221, 227, 253;
  --ion-color-btaction-contrast: #1e2023;
  --ion-color-btaction-contrast-rgb: 30, 32, 35;
  --ion-color-btaction-shade: #808289;
  --ion-color-btaction-tint: #9d9fa6;

  --ion-color-btreaction: #ffd3e1;
  --ion-color-btreaction-rgb: 255, 211, 225;
  --ion-color-btreaction-contrast: #1e2023;
  --ion-color-btreaction-contrast-rgb: 30, 32, 35;
  --ion-color-btreaction-shade: #808289;
  --ion-color-btreaction-tint: #9d9fa6;

  --ion-color-btsubmission: #fdcbf1;
  --ion-color-btsubmission-rgb: 253, 203, 241;
  --ion-color-btsubmission-contrast: #1e2023;
  --ion-color-btsubmission-contrast-rgb: 30, 32, 35;
  --ion-color-btsubmission-shade: #808289;
  --ion-color-btsubmission-tint: #9d9fa6;

  --ion-color-btposition: #d4f4f6;
  --ion-color-btposition-rgb: 212, 244, 246;
  --ion-color-btposition-contrast: #1e2023;
  --ion-color-btposition-contrast-rgb: 30, 32, 35;
  --ion-color-btposition-shade: #808289;
  --ion-color-btposition-tint: #9d9fa6;

  --ion-color-btsystem: #e0c3fc;
  --ion-color-btsystem-rgb: 224, 195, 252;
  --ion-color-btsystem-contrast: #ffffff;
  --ion-color-btsystem-contrast-rgb: 255, 255, 255;
  --ion-color-btsystem-shade: #4854e0;
  --ion-color-btsystem-tint: #6370ff;

  /** btblue **/
  --ion-color-btblue: #a6c1ee;
  --ion-color-btblue-rgb: 166, 193, 238;
  --ion-color-btblue-contrast: #ffffff;
  --ion-color-btblue-contrast-rgb: 255, 255, 255;
  --ion-color-btblue-shade: #36abe0;
  --ion-color-btblue-tint: #50c8ff;

  /** btpink **/
  --ion-color-btpink: #e0c3fc;
  --ion-color-btpink-rgb: 224, 195, 252;
  --ion-color-btpink-contrast: #ffffff;
  --ion-color-btpink-contrast-rgb: 255, 255, 255;
  --ion-color-btpink-shade: #4854e0;
  --ion-color-btpink-tint: #6370ff;
}

/** Remove Ionic's default behavior of uppercasing all text **/
ion-button {
  text-transform: none;
}

.ion-color-btaction {
  --ion-color-base: var(--ion-color-btaction);
  --ion-color-base-rgb: var(--ion-color-btaction-rgb);
  --ion-color-contrast: var(--ion-color-btaction-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btaction-contrast-rgb);
  --ion-color-shade: var(--ion-color-btaction-shade);
  --ion-color-tint: var(--ion-color-fbtaction-tint);
}

.ion-color-btreaction {
  --ion-color-base: var(--ion-color-btreaction);
  --ion-color-base-rgb: var(--ion-color-btreaction-rgb);
  --ion-color-contrast: var(--ion-color-btreaction-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btreaction-contrast-rgb);
  --ion-color-shade: var(--ion-color-btreaction-shade);
  --ion-color-tint: var(--ion-color-btreaction-tint);
}

.ion-color-btsubmission {
  --ion-color-base: var(--ion-color-btsubmission);
  --ion-color-base-rgb: var(--ion-color-btsubmission-rgb);
  --ion-color-contrast: var(--ion-color-btsubmission-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btsubmission-contrast-rgb);
  --ion-color-shade: var(--ion-color-btsubmission-shade);
  --ion-color-tint: var(--ion-color-btsubmission-tint);
}

.ion-color-btposition {
  --ion-color-base: var(--ion-color-btposition);
  --ion-color-base-rgb: var(--ion-color-btposition-rgb);
  --ion-color-contrast: var(--ion-color-btposition-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btposition-contrast-rgb);
  --ion-color-shade: var(--ion-color-btposition-shade);
  --ion-color-tint: var(--ion-color-btposition-tint);
}

.ion-color-btsystem {
  --ion-color-base: var(--ion-color-btsystem);
  --ion-color-base-rgb: var(--ion-color-btsystem-rgb);
  --ion-color-contrast: var(--ion-color-btsystem-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btsystem-contrast-rgb);
  --ion-color-shade: var(--ion-color-btsystem-shade);
  --ion-color-tint: var(--ion-color-btsystem-tint);
}

.ion-color-btblue {
  --ion-color-base: var(--ion-color-btblue);
  --ion-color-base-rgb: var(--ion-color-btblue-rgb);
  --ion-color-contrast: var(--ion-color-btblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-btblue-shade);
  --ion-color-tint: var(--ion-color-btblue-tint);
}

.ion-color-btpink {
  --ion-color-base: var(--ion-color-btpink);
  --ion-color-base-rgb: var(--ion-color-btpink-rgb);
  --ion-color-contrast: var(--ion-color-btpink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-btpink-contrast-rgb);
  --ion-color-shade: var(--ion-color-btpink-shade);
  --ion-color-tint: var(--ion-color-btpink-tint);
}
