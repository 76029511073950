.tagged-item {
  margin-left: 25px;
}

.color-box {
  float: left;
  height: 20px;
  width: 20px;
  border: 0px;
  clear: both;
  margin-right: 10px;
  border-radius: 4px;
}